import {
	cancelPatientSignatureApi,
	createPrescriptionApi,
	getExamDocumentsFromReferenceApi,
	getPrescriptionApi,
	getUserByUsernameApi,
	sendPrescriptionDocumentApi,
	updatePrescriptionApi,
} from '../../apiCalls'
import notificationsActions from '../../features/notifications/actions'
import i18n from 'i18next'
import { formatName } from '../../libs/localization'
import { convertDateForBody, isValidDate } from '../../libs/time'
import { Base64Image, Id } from '../../model/model'
import { Prescription, PrescriptionData } from '../../model/prescription'
import {
	AppThunkPromise,
	TeloDispatch,
	TeloGetState,
	AppThunk,
} from '../../store'
import { selectUsername } from '../auth/selectors'
import cockpitActions from '../cockpit/actions'
import { selectExam } from '../exams/selectors'
import { selectUser } from '../users/selectors'
import { slice } from './slice'
import { selectPatientSignatureSource } from '../prescription/selectors'
import signRequestsActions from '../signRequests/actions'

export const createPrescription =
	({
		examId,
		prescriptionData,
		contactLensesSignature,
		signed = true,
	}: {
		examId: Id
		prescriptionData: PrescriptionData
		contactLensesSignature?: Base64Image
		signed?: boolean
	}): AppThunkPromise<{ payload: Prescription; type: string } | undefined> =>
	(dispatch: TeloDispatch, getState: TeloGetState) => {
		const state = getState()
		const username = selectUsername(state)

		const patientSignatureSource = selectPatientSignatureSource(state)

		return getUserByUsernameApi(username.toLowerCase()).then(user => {
			if (!user) {
				throw new Error(`No user found for username ${username}`)
			}
			const exam = selectExam(examId)(state)
			if (!exam) {
				return
			}

			const patient = {
				fullname: formatName(exam.internalPatient),
				birthDate: exam.internalPatient.birthDate
					? convertDateForBody(new Date(exam.internalPatient.birthDate))
					: undefined,
				email: exam.internalPatient.email || '',
			}

			return createPrescriptionApi({
				examId,
				userId: user._id,
				appointment: exam.externalAppointment,
				prescriptionData,
				contactLensesSignature,
				patient,
				signed,
				patientSignatureSource,
			}).then(prescription => {
				if (prescription) {
					dispatch(slice.actions._clearUndefinedPrescription())
					return dispatch(slice.actions._loadPrescription(prescription))
				}
			})
		})
	}

export const updatePrescription =
	({
		contactLensesSignature,
		examId,
		prescriptionData,
		prescriptionId,
		send = false,
		isSignNeeded = false,
		blockPdfUpdating,
	}: {
		contactLensesSignature?: Base64Image
		examId: Id
		prescriptionData: PrescriptionData
		prescriptionId: Id
		send: boolean
		isSignNeeded?: boolean
		blockPdfUpdating?: boolean
	}): AppThunkPromise<{ payload: Prescription; type: string } | void> =>
	(dispatch: TeloDispatch, getState: TeloGetState) => {
		const state = getState()
		const user = selectUser(state)
		const exam = selectExam(examId)(state)

		const patientSignatureSource = selectPatientSignatureSource(state)

		if (!user || !exam) {
			return Promise.reject()
		}

		const examDate = exam.history[0].statusUpdatedAt
		let prescriptionDate = ''

		if (isValidDate(examDate)) {
			prescriptionDate = examDate.toISOString()
		} else if (typeof examDate === 'string') {
			prescriptionDate = examDate
		}

		const patient = {
			fullname: formatName(exam.internalPatient),
			birthDate: exam.internalPatient.birthDate
				? convertDateForBody(new Date(exam.internalPatient.birthDate))
				: undefined,
			email: exam.internalPatient.email || '',
		}

		return updatePrescriptionApi({
			examId,
			userId: user._id,
			appointment: exam?.externalAppointment,
			prescriptionData,
			contactLensesSignature,
			prescriptionId,
			signature: !isSignNeeded,
			send,
			patient,
			patientSignatureSource,
			date: prescriptionDate,
			blockPdfUpdating,
		}).then(prescription => {
			if (prescription) {
				dispatch(slice.actions._loadPrescription(prescription))
			}
			if (prescription && !!prescription.sent) {
				dispatch(
					notificationsActions.addNotification({
						message: i18n.t('doctor.signNotification'),
						autoClose: true,
						type: 'success',
					}),
				)
			}
		})
	}

const cancelSignatureRequest =
	(
		prescriptionId: Id,
	): AppThunkPromise<{ payload: Prescription; type: string } | void> =>
	(dispatch: TeloDispatch) => {
		return cancelPatientSignatureApi(prescriptionId).then(
			prescription =>
				prescription && dispatch(slice.actions._loadPrescription(prescription)),
		)
	}

const fetchPrescription =
	(examId: Id): AppThunk =>
	(dispatch: TeloDispatch) => {
		getPrescriptionApi(examId).then(
			prescription =>
				prescription && dispatch(slice.actions._loadPrescription(prescription)),
		)
	}

const setInDisplay =
	(key?: string, index?: number, skipClear?: boolean): AppThunk =>
	(dispatch: TeloDispatch) => {
		if (!skipClear) {
			dispatch(cockpitActions.clearCockpitDisplay())
		}

		const rxSharingKey =
			key && index !== undefined ? `${key}${index}` : undefined
		dispatch(slice.actions.setInShare(rxSharingKey))
		dispatch(cockpitActions._setSharingOn(key ? key : ''))
	}

const clearPrescriptionDisplay = (): AppThunk => (dispatch: TeloDispatch) => {
	dispatch(slice.actions.setInShare())
}

const sendPrescriptionDocument =
	({
		examId,
		prescriptionId,
		send,
	}: {
		examId: Id
		prescriptionId: Id
		send: boolean
	}): AppThunkPromise<{ payload: Prescription; type: string } | void> =>
	(dispatch: TeloDispatch, getState: TeloGetState) => {
		const state = getState()
		const username = selectUsername(state)
		return getUserByUsernameApi(username.toLowerCase()).then(user => {
			if (!user) {
				throw new Error(`No user found for username ${username}`)
			}
			const exam = selectExam(examId)(state)
			if (!exam) {
				return
			}

			return sendPrescriptionDocumentApi(prescriptionId, {
				send,
			}).then(
				prescription =>
					prescription &&
					dispatch(slice.actions._loadPrescription(prescription)),
			)
		})
	}

const getPrescriptionBlob =
	(prescriptionId: string, fileRef: string): AppThunk =>
	(dispatch: TeloDispatch) => {
		return getExamDocumentsFromReferenceApi(fileRef, 'ppd').then(doc => {
			dispatch(
				slice.actions._savePdfDocument({
					prescriptionId,
					doc: window.URL.createObjectURL(doc),
				}),
			)
		})
	}

const setIsDirty =
	(isDirty: boolean): AppThunk =>
	(dispatch: TeloDispatch) => {
		dispatch(slice.actions._setIsDirty(isDirty))
	}

const setHasErrors =
	(hasErrors: boolean): AppThunk =>
	(dispatch: TeloDispatch) => {
		dispatch(slice.actions._setHasErrors(hasErrors))
	}

const prescriptionsActions = {
	updatePrescription,
	createPrescription,
	fetchPrescription,
	setInDisplay,
	clearPrescriptionDisplay,
	sendPrescriptionDocument,
	getPrescriptionBlob,
	setIsDirty,
	setHasErrors,
	cancelSignatureRequest,
}

export default prescriptionsActions
