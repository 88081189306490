import { RootState } from '../../model/model'

export const selectAzureCommunicationData = (state: RootState) =>
	state.azureCommunication.data

export const selectAzureCommunicationToken = (state: RootState) =>
	state.azureCommunication.data.token

export const selectAzureCommunicationStatus = (state: RootState) =>
	state.azureCommunication.loginStatus

export const selectAzureCommunicationUserId = (state: RootState) =>
	state.azureCommunication.data.communicationUserId

export const selectAzureCommunicationExam = (state: RootState) =>
	state.azureCommunication.data.relatedToExam

export const selectAzureStatusIndicator = (state: RootState) =>
	state.azureCommunication.statusIndicator

export const selectTeloCallStatus = (state: RootState) =>
	state.azureCommunication.teloCallStatus

export const selectCallee = (state: RootState) =>
	state.azureCommunication.callee
