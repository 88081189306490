import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { pxToRem } from '../../../libs/style'
import theme from '../../../styleguide/theme'
import { AZURE_PALETTE } from '../../../styleguide/themePalette'

type StatusIndicatorProps = {
	active: boolean
	error: boolean
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
`

const ContainerStatus = styled.div`
	display: flex;
	flex-direction: row;
`

const Indicator = styled.span`
	display: inline-block;
	width: 0.8em;
	height: 0.8em;
	border-radius: 50%;
`

const InitIndicator = styled(Indicator)`
	background: gray;
`

const OnlineIndicator = styled(Indicator)`
	background: ${AZURE_PALETTE.GREEN};
`

const OfflineIndicator = styled(Indicator)`
	background: ${AZURE_PALETTE.RED};
`

const IndicatorContainer = styled.div`
	margin-right: ${pxToRem(9)}rem;
`
const LabelContainer = styled.div`
	font-size: ${pxToRem(12)}rem;
	color: ${theme.palette.primary.main};
`
const SublabelContainer = styled.div`
	font-size: ${pxToRem(10)}rem;
	color: ${theme.palette.grey[400]};
`

const AzureStatusIndicator: React.FC<StatusIndicatorProps> = ({
	active,
	error,
}) => {
	const { t } = useTranslation()
	const status = error
		? 'VC init failed'
		: active
		? 'VC active'
		: 'Connecting...'

	const indicator = error ? (
		<OfflineIndicator />
	) : active ? (
		<OnlineIndicator />
	) : (
		<InitIndicator />
	)

	return (
		<Container>
			<ContainerStatus>
				<IndicatorContainer>{indicator}</IndicatorContainer>
				<LabelContainer>{status}</LabelContainer>
			</ContainerStatus>
			{error && (
				<SublabelContainer>
					{t('azureCommunication.pleaseReload')}
				</SublabelContainer>
			)}
		</Container>
	)
}

export default AzureStatusIndicator
