import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ContactLenses, ContactLensesCatalogData } from '../../model/exam'
import { ContactLensesState } from '../../model/model'

const initialState: ContactLensesState = {
	catalog: [],
	sameProductBothEyesEnabledByTab: {
		0: true,
		1: true,
		2: true,
		3: true,
		4: true,
		5: true,
	},
}

export type SetSameProductBothEyesEnabledData = {
	tabId: number
	enabled: boolean
}

export const slice = createSlice({
	name: 'contactLenses',
	initialState,
	reducers: {
		_loadContactLensesCatalog: (
			state,
			{ payload }: PayloadAction<ContactLensesCatalogData[]>,
		) => {
			state.catalog = payload
		},
		_loadSameProductBothEyesEnabled: (
			state,
			{ payload: contactLenses }: PayloadAction<ContactLenses[]>,
		) => {
			contactLenses.forEach((cl, tabId) => {
				state.sameProductBothEyesEnabledByTab[tabId] =
					cl.lensType.OD === cl.lensType.OS && cl.selectedEye === 'BIN'
			})
		},
		setSameProductBothEyesEnabled: (
			state,
			{
				payload: { tabId, enabled },
			}: PayloadAction<SetSameProductBothEyesEnabledData>,
		) => {
			state.sameProductBothEyesEnabledByTab[tabId] = enabled
		},
	},
})

export const {
	setSameProductBothEyesEnabled,
	_loadSameProductBothEyesEnabled,
} = slice.actions

export default slice.reducer
