type DiagnosticType =
    'speakingWhileMicrophoneIsMuted' |
    'noSpeakerDevicesEnumerated' |
    'noMicrophoneDevicesEnumerated' |
    'cameraFreeze' |
    'cameraStartFailed' |
    'cameraStartTimedOut' |
    'capturerStartFailed' |
    'microphoneNotFunctioning' |
    'microphoneMuteUnexpectedly' |
    'cameraStoppedUnexpectedly' |
    'capturerStoppedUnexpectedly' |
    'screenshareRecordingDisabled' |
    'microphonePermissionDenied' |
    'cameraPermissionDenied' |
    'noNetwork' |
    'networkRelaysNotReachable' |
    'networkReconnect' |
    'networkReceiveQuality' |
    'networkSendQuality'

export type UfdMessage = {
    category: 'MediaDiagnostics' | 'LatestNetworkDiagnostics',
    diagnostic: DiagnosticType,
    value: number | boolean,
    message: string,
}

export const UFD_MESSAGES: UfdMessage[] = [
    { category: 'MediaDiagnostics', diagnostic: 'speakingWhileMicrophoneIsMuted', value: true, message: 'Local microphone is muted and the local user is speaking.', },
    { category: 'MediaDiagnostics', diagnostic: 'speakingWhileMicrophoneIsMuted', value: false, message: 'Local user either stops speaking, or unmutes the microphone.', },
    { category: 'MediaDiagnostics', diagnostic: 'noSpeakerDevicesEnumerated', value: true, message: 'There are no speaker devices on the system, and speaker selection is supported.' },
    { category: 'MediaDiagnostics', diagnostic: 'noSpeakerDevicesEnumerated', value: false, message: 'There is a least 1 speaker device on the system, and speaker selection is supported.' },
    { category: 'MediaDiagnostics', diagnostic: 'noMicrophoneDevicesEnumerated', value: true, message: 'There are no microphone devices on the system.' },
    { category: 'MediaDiagnostics', diagnostic: 'noMicrophoneDevicesEnumerated', value: false, message: 'There is at least 1 microphone device on the system.' },
    { category: 'MediaDiagnostics', diagnostic: 'cameraFreeze', value: true, message: 'Local video stream is frozen. This means the remote side is seeing your video frozen on their screen.' },
    { category: 'MediaDiagnostics', diagnostic: 'cameraFreeze', value: false, message: 'Freeze ends.' },
    { category: 'MediaDiagnostics', diagnostic: 'cameraStartFailed', value: true, message: 'Failed to start sending local video becuase the camera device may have been disabled in the system or it is being used by another process.' },
    { category: 'MediaDiagnostics', diagnostic: 'cameraStartFailed', value: false, message: 'Selected camera device successfully sends local video again.' },
    { category: 'MediaDiagnostics', diagnostic: 'cameraStartTimedOut', value: true, message: 'Camera device times out to start sending video stream.' },
    { category: 'MediaDiagnostics', diagnostic: 'cameraStartTimedOut', value: false, message: 'Selected camera device successfully sends local video again' },
    { category: 'MediaDiagnostics', diagnostic: 'capturerStartFailed', value: true, message: 'Failed to start capturing the screen.' },
    { category: 'MediaDiagnostics', diagnostic: 'capturerStartFailed', value: false, message: 'Capturing the screen successfully can start.' },
    { category: 'MediaDiagnostics', diagnostic: 'microphoneNotFunctioning', value: true, message: 'Failed to start sending local audio stream becuase the microphone device may have been disabled in the system or it is being used by another process.' },
    { category: 'MediaDiagnostics', diagnostic: 'microphoneNotFunctioning', value: false, message: 'Microphone starts to successfully send audio stream again.' },
    { category: 'MediaDiagnostics', diagnostic: 'microphoneMuteUnexpectedly', value: true, message: 'Microphone enters muted state unexpectedly.' },
    { category: 'MediaDiagnostics', diagnostic: 'microphoneMuteUnexpectedly', value: false, message: 'Microphone starts to successfully send audio stream again.' },
    { category: 'MediaDiagnostics', diagnostic: 'cameraStoppedUnexpectedly', value: true, message: 'Camera enters stopped state unexpectedly.' },
    { category: 'MediaDiagnostics', diagnostic: 'cameraStoppedUnexpectedly', value: false, message: 'Camera starts to successfully send video stream again.' },
    { category: 'MediaDiagnostics', diagnostic: 'capturerStoppedUnexpectedly', value: true, message: 'Screen capturer enters stopped state unexpectedly.' },
    { category: 'MediaDiagnostics', diagnostic: 'capturerStoppedUnexpectedly', value: false, message: 'Screen capturer starts to successfully capture again.' },
    { category: 'MediaDiagnostics', diagnostic: 'screenshareRecordingDisabled', value: true, message: 'Screensharing permission is denied by system settings (sharing).' },
    { category: 'MediaDiagnostics', diagnostic: 'screenshareRecordingDisabled', value: false, message: 'Successful stream acquisition.' },
    { category: 'MediaDiagnostics', diagnostic: 'microphonePermissionDenied', value: true, message: 'Audio permission is denied by system settings (audio).', },
    { category: 'MediaDiagnostics', diagnostic: 'microphonePermissionDenied', value: false, message: 'Successful stream acquisition.', },
    { category: 'MediaDiagnostics', diagnostic: 'cameraPermissionDenied', value: true, message: 'Camera permission is denied by system settings (video).' },
    { category: 'MediaDiagnostics', diagnostic: 'cameraPermissionDenied', value: false, message: 'Successful stream acquisition.' },
    { category: 'LatestNetworkDiagnostics', diagnostic: 'networkReconnect', value: 3, message: 'Network is disconnected and unable to reconnect.' },
    { category: 'LatestNetworkDiagnostics', diagnostic: 'networkReconnect', value: 2, message: 'Media transport connectivity is lost.' },
    { category: 'LatestNetworkDiagnostics', diagnostic: 'networkReconnect', value: 1, message: 'New session is connected.' },
    { category: 'LatestNetworkDiagnostics', diagnostic: 'networkReceiveQuality', value: 3, message: 'There is a severe problem with recv quality.' },
    { category: 'LatestNetworkDiagnostics', diagnostic: 'networkReceiveQuality', value: 2, message: 'There is a mild problem with recv quality.' },
    { category: 'LatestNetworkDiagnostics', diagnostic: 'networkReceiveQuality', value: 1, message: 'There is no problem with recv quality.' },
    { category: 'LatestNetworkDiagnostics', diagnostic: 'networkSendQuality', value: 3, message: 'There is a severe problem with send quality.' },
    { category: 'LatestNetworkDiagnostics', diagnostic: 'networkSendQuality', value: 2, message: 'There is a mild problem with send quality.' },
    { category: 'LatestNetworkDiagnostics', diagnostic: 'networkSendQuality', value: 1, message: 'There is no problem with send quality.' },
    { category: 'LatestNetworkDiagnostics', diagnostic: 'noNetwork', value: true, message: 'Call fails to start because there is no network.' },
    { category: 'LatestNetworkDiagnostics', diagnostic: 'noNetwork', value: false, message: 'There are ice candidates present.' },
    { category: 'LatestNetworkDiagnostics', diagnostic: 'networkRelaysNotReachable', value: true, message: 'The network has some constraint that is not allowing to reach ACS relays.' },
    { category: 'LatestNetworkDiagnostics', diagnostic: 'networkRelaysNotReachable', value: false, message: 'Making a new call.' },
]