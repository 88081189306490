import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import config from '../config'
import { selectInStore, selectStoreId } from '../features/app/selectors'
import { selectUsername, selectUserRole } from '../features/auth/selectors'
import AzureStatusIndicator from '../features/azureCommunication/components/AzureStatusIndicator'
import { selectAzureStatusIndicator } from '../features/azureCommunication/selectors'
import { selectExam } from '../features/exams/selectors'
import { selectDoctorPanel } from '../features/panels/selectors'

import searchActions from '../features/search/actions'
import { selectSearchTerm } from '../features/search/selectors'
import usersActions from '../features/users/actions'
import {
	selectIsDoctor,
	selectIsLocalTechnician,
	selectIsRefractionist,
	selectUserByUsername,
} from '../features/users/selectors'
import useEclipsMetadata from '../hooks/useEclipsMetadata'
import { getUserDashboard } from '../libs/auth'
import { lastExamDoctor } from '../libs/exams'
import { isTodayZoned } from '../libs/time'
import { showDebugMode } from '../libs/utils'
import { useTeloDispatch, useTeloSelector } from '../store'
import Button from '../styleguide/buttons/Button'
import { pageWrapperPadding } from '../styleguide/CommonPageComponents'
import HomeIcon from '../styleguide/icons/HomeIcon'
import TargetIcon from '../styleguide/icons/TargetIcon'
import LinkNoStyle from '../styleguide/LinkNoSyle'
import Switch from '../styleguide/Switch'
import theme from '../styleguide/theme'
import { IMAGES } from '../utils/assetRegistry'
import { DebugModeSwitch } from './DebugMode'
import ProfileMenu from './ProfileMenu'
import worklistActions from '../features/worklist/actions'
import appConfig from '../config'
import { useTeloRouter } from '../routing/teloRouter'
import { useCheckCurrentPage } from '../routing/teloRouterUtils'

const ConnectHeader = styled.header`
	${pageWrapperPadding}
	padding-top: ${theme.spacing(2)};
	padding-bottom: ${theme.spacing(4)};
	display: grid;
	grid-template-columns: 41% 18% 41%;
	width: 100%;
	position: relative;
	z-index: ${theme.zIndex.header};
	background-color: ${theme.palette.grey[100]};
	margin-left: 0;
	margin-right: 0;
`

const EclipsHeader = styled.header`
	${pageWrapperPadding}
	padding-top: ${theme.spacing(2)};
	padding-bottom: ${theme.spacing(4)};
	display: flex;
	width: 100%;
	z-index: ${theme.zIndex.header};
	background-color: ${theme.palette.grey[100]};
	justify-content: space-between;
`

const Logo = styled.img<{ visionx: boolean }>`
	width: ${props => (props.visionx === true ? '70%' : '100%')};
	height: auto;
	display: block;
	grid-column: 2;
	place-self: center center;
`

const HomeButton = styled(LinkNoStyle)`
	grid-column: 1;
	place-self: center left;
	.MuiButtonBase-root {
		padding: 0;
	}
`

const RightCol = styled.div`
	grid-column: 3;
	place-self: center right;
	display: flex;
	align-items: center;
`

const LeftCol = styled.div`
	grid-column: 1;
	place-self: center left;
	display: flex;
	align-items: center;
`

const ReloadButton = styled(Button)`
	grid-column: 1;
	place-self: center left;
	.MuiButtonBase-root {
		padding: 0;
	}
`

const AvailabilitySwitch = styled(Switch)`
	color: ${props =>
		props.checked ? theme.palette.secondary.main : theme.palette.primary.main};
`

const AzureStatusIndicatorContainer = styled.div`
	margin-left: ${theme.spacing(2)};
	margin-top: ${theme.spacing(2)};
`
const MissionControlButtonStyled = styled(Button)<{
	$isappinsideeclips: boolean
}>`
	${({ $isappinsideeclips }) =>
		$isappinsideeclips === true
			? `
		padding: 0;
		min-width: auto;
		width: auto;
	`
			: ''}
`

interface Props {
	hideHomeIcon?: boolean
	hideProfileMenu?: boolean
	hideUnavailable?: boolean
	examId?: string
	centered?: boolean
	logoSrc?: string
	isMissionControlOpen?: boolean
	openMissionControl?: () => void
}

const Header: React.FC<Props> = ({
	hideHomeIcon,
	hideProfileMenu,
	logoSrc = config.region === 'GEMINI'
		? IMAGES.VISIONX_LOGO_URL
		: IMAGES.CONNECT_LOGO_URL,
	hideUnavailable,
	isMissionControlOpen,
	openMissionControl,
	examId,
}) => {
	const dispatch = useTeloDispatch()
	const { t } = useTranslation()
	const exam = useTeloSelector(selectExam(examId || ''))

	const $checkedInAt = exam?.checkedInAt
	const $timezone = exam?.store.timezone
	const isTodayExam = useMemo(() => {
		if (!$checkedInAt || !$timezone) {
			return false
		}
		return isTodayZoned($checkedInAt, $timezone)
	}, [$checkedInAt, $timezone])

	const userRole = useTeloSelector(selectUserRole)
	const storeId = useTeloSelector(selectStoreId)

	const { isWorklistPage, isExamPage } = useCheckCurrentPage()

	const inStore = useTeloSelector(selectInStore)
	const isDoctor = useTeloSelector(selectIsDoctor)
	const isRefractionist = useTeloSelector(selectIsRefractionist)
	const username = useTeloSelector(selectUsername)
	const user = useTeloSelector(selectUserByUsername(username))
	const doctorPanel = useTeloSelector(selectDoctorPanel)
	const searchTerm = useTeloSelector(selectSearchTerm)
	const azureStatusIndicator = useTeloSelector(selectAzureStatusIndicator)
	const eclipsMetadata = useEclipsMetadata()
	const isAppInsideEclips = eclipsMetadata !== undefined
	const isTechnician = useTeloSelector(selectIsLocalTechnician)

	const showUnavailableButton =
		!hideUnavailable &&
		(isDoctor || (isRefractionist && !inStore)) &&
		isWorklistPage

	const showReloadButton = isWorklistPage && !isMissionControlOpen

	const isPanelAssigned = doctorPanel.length > 0

	const showAzureStatusIndicator =
		azureStatusIndicator.show === true &&
		((isExamPage && lastExamDoctor(exam) === username) ||
			(!isDoctor && !isRefractionist)) &&
		isTodayExam

	const homeButtonDestination = getUserDashboard(userRole, storeId)
	const clearSearchTerm = () => {
		searchTerm && dispatch(searchActions.clearSearch())
	}

	const reloadButton = (
		<ReloadButton
			variant="text"
			underlined={true}
			onClick={() => {
				window.location.href = `${window.location.protocol}//${window.location.host}${window.location.pathname}`
			}}
			className="ott-ltDashboard-ReloadButton"
		>
			{t('app.reload')}
		</ReloadButton>
	)

	const homeButton = (
		<HomeButton to={homeButtonDestination} onClick={() => clearSearchTerm()}>
			<Button variant="text" startIcon={<HomeIcon />} underlined={true}>
				{t('app.home')}
			</Button>
		</HomeButton>
	)

	const rightColumnContent = (
		<RightCol>
			{showDebugMode === true ? <DebugModeSwitch /> : null}
			{showUnavailableButton && user && (
				<AvailabilitySwitch
					checked={user.available}
					label={t('app.available')}
					onChange={() => {
						dispatch(worklistActions.setAvailableToggleWasClicked(true))
						dispatch(
							usersActions.setUserAvailability(user._id, !user.available),
						)
						dispatch(worklistActions.setCurrentPaginationPage(1)) //necessary for refetching
					}}
				/>
			)}
			{isWorklistPage && ((isDoctor && isPanelAssigned) || !isDoctor) ? (
				<MissionControlButtonStyled
					$isappinsideeclips={isAppInsideEclips}
					startIcon={
						<TargetIcon
							fill={
								isMissionControlOpen ? theme.palette.secondary.main : undefined
							}
						/>
					}
					variant="text"
					color="secondary"
					onClick={openMissionControl}
				/>
			) : null}
			{hideProfileMenu ? <span></span> : <ProfileMenu />}
		</RightCol>
	)

	const buttonToShow = (isAppInsideEclips: boolean) => {
		if (isAppInsideEclips === false) {
			if (showReloadButton) {
				return reloadButton
			} else if (!hideHomeIcon) {
				return homeButton
			}
		} else {
			if (showReloadButton) {
				return reloadButton
			}
		}
	}

	return isAppInsideEclips === false ? (
		<ConnectHeader>
			<LeftCol>
				{buttonToShow(false)}
				{showAzureStatusIndicator === true && (
					<AzureStatusIndicatorContainer>
						<AzureStatusIndicator
							active={azureStatusIndicator.active}
							error={azureStatusIndicator.error}
						/>
					</AzureStatusIndicatorContainer>
				)}
			</LeftCol>
			<Logo
				alt="Luxottica Optometrist"
				src={logoSrc}
				visionx={config.region === 'GEMINI'}
			/>
			<RightCol>{rightColumnContent}</RightCol>
		</ConnectHeader>
	) : (
		<EclipsHeader>
			<div>{buttonToShow(true)}</div>
			<div>{rightColumnContent}</div>
		</EclipsHeader>
	)
}

export default Header
